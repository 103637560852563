.blog-grid-row {
    margin-bottom: 50px;
    display: flex;
    padding: 0 40px;
}

.blog-grid-item {
    max-width: 25%;
    margin: 10px!important;
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px;
}

.grid-item-content {
    text-align: center;
}

img.FeaturImage {
    min-width: 100%;
    max-width: 100%;
    max-height: 150px;
    min-height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

span.item-title {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    display: block;
    margin-bottom: 8px !important;
    margin-top:8px!important;
    text-align: center;
}

span.item-excerpt {
    font-size: 12px;
    line-height: 18px;
    display:block;
    text-align: center;
}

.button-readmore {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0 20px 0;
  min-height: 40px;
  min-width: 0;
  outline: none;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 75%;
  will-change: transform;
}

.button-readmore:disabled {
  pointer-events: none;
}

.button-readmore:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-readmore:active {
  box-shadow: none;
  transform: translateY(0);
}



@media only screen and (max-width: 600px) {
    .blog-grid-row {
        margin-bottom: 50px;
        display: block;
        padding: 0 60px;
    }
    
    .blog-grid-item {
        max-width: 100%;
        min-width:100%;
        margin: 10px!important;
        border: 1px solid black;
        border-radius: 20px;
        padding: 10px;
    }
    
    .grid-item-content {
        text-align: center;
    }
    
    img.FeaturImage {
        min-width: 100%;
        max-width: 100%;
        max-height: 150px;
        min-height: 150px;
        object-fit: cover;
        border-radius: 10px;
    }
    
    span.item-title {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Poppins';
        display: block;
        margin-bottom: 8px !important;
        margin-top:8px!important;
        text-align: center;
    }
    
    span.item-excerpt {
        font-size: 12px;
        line-height: 18px;
        display:block;
        text-align: center;
    }
    
    .button-readmore {
      appearance: none;
      background-color: #000000;
      border: 2px solid #1A1A1A;
      border-radius: 15px;
      box-sizing: border-box;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      margin: 20px 0 20px 0;
      min-height: 40px;
      min-width: 0;
      outline: none;
      padding: 8px 24px;
      text-align: center;
      text-decoration: none;
      transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      width: 75%;
      will-change: transform;
    }
    
    .button-readmore:disabled {
      pointer-events: none;
    }
    
    .button-readmore:hover {
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
    }
    
    .button-readmore:active {
      box-shadow: none;
      transform: translateY(0);
    }
  }