.landing-page-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.landing-page-top-container {
  width: 100%;
  height: 419px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/wordpress_16433693891-1500w.png");
  background-position: 0% 40%;
}

.landing-page-top-blog-container {
  width: 100%;
  height: 419px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: 0% 40%;
}

.landing-page-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

#main-section {
  max-width: 1100px;
}
.landing-page-logo {
  color: #ffffff;
}
.landing-page-right-side {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-burger-menu1 {
  width: 24px;
  height: 24px;
}
.landing-page-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.landing-page-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-icon03 {
  width: 24px;
  height: 24px;
}
.landing-page-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-links-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text {
  margin-bottom: 8px;
}
.landing-page-text01 {
  margin-bottom: 8px;
}
.landing-page-text02 {
  margin-bottom: 8px;
}
.landing-page-link {
  display: contents;
}
.landing-page-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text04 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link1 {
  display: contents;
}
.landing-page-icon05 {
  width: 24px;
  height: 24px;
}
.landing-page-link2 {
  display: contents;
}
.landing-page-icon07 {
  width: 24px;
  height: 24px;
}
.landing-page-link3 {
  display: contents;
}
.landing-page-icon09 {
  width: 24px;
  height: 24px;
}
.landing-page-hero {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1100px;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.landing-page-content-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-text05 {
  color: rgb(255, 255, 255);
}
.landing-page-text09 {
  color: #ffffff;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-main {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
}
.landing-page-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.landing-page-menu {
  width: 100%;
  display: flex;
  max-width: 1040px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-follow-container1 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text14 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-icons-container1 {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link4 {
  display: contents;
}
.landing-page-icon11 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.landing-page-link5 {
  display: contents;
}
.landing-page-icon13 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.landing-page-link6 {
  display: contents;
}
.landing-page-icon15 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .landing-page-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .landing-page-right-side {
    display: none;
  }
  .landing-page-burger-menu {
    display: flex;
  }
  .landing-page-hero {
    justify-content: center;
  }
  .landing-page-content-container {
    align-items: center;
  }
  .landing-page-text05 {
    font-size: 2.5rem;
  }
  .landing-page-text09 {
    text-align: center;
  }
  .landing-page-main {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .landing-page-menu {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .landing-page-text05 {
    font-size: 2rem;
    text-align: center;
  }
  .landing-page-follow-container1 {
    margin-left: 0px;
  }
}
